export const CLASSES = {
  IS_ACTIVE: "is-active",
  IS_OPEN: "is-open",
  IS_EDITABLE: "is-editable",
  IS_HIDDEN: "is-hidden",
  IS_VISIBLE: "is-visible",
  LOADER: "loader",
  LOADER_WRAPPER: "loader-wrapper",
  FORM_INPUT: "form-input",
  FORM_CHECKBOX: "checkbox",
  FORM_SUBMIT: "form-submit",
  TOOLTIP_ACTIVE: "tooltipstered"
};

export const HOSTS = {
  STATIC: window.static_server || '',
}

export const STRINGS = {
  ERROR_MESSAGE_DEFAULT: "Sorry, something went wrong",
  ERROR_MESSAGE_EMAIL: "Please enter your email",
  ERROR_MESSAGE_REQUIRED_FIELD: "This field is required",
  ERROR_MESSAGEAGREEMENT:
    "In order to proceed, you must agree with Terms and Conditions",
  ERROR_MESSAGE_CREDIT_CARD_FIRST_NAMECREDIT_CARD_FIRST_NAME:
    "Please enter your first name as it appears on your credit card",
  ERROR_MESSAGE_CREDIT_CARD_LAST_NAME:
    "Please enter your last name as it appears on your credit card",
  ERROR_MESSAGE_CREDIT_CARD_NUMBER: "Please enter your credit card number",
  CREDIT_CARD_EXP_DATE:
    "Please enter your credit card’s expiration date",
  ERROR_MESSAGE_CREDIT_CARD_SECURITY_CODE:
    "Please enter your credit card’s security code",
  ERROR_MESSAGE_CREDIT_CARD_ZIP_CODE: "Please enter your billing zip code",
  ERROR_MESSAGE_FIRST_NAME: "Please enter your first name",
  ERROR_MESSAGE_LAST_NAME: "Please enter your last name",
  ERROR_MESSAGE_PHONE: "Please enter your phone number",
  ERROR_MESSAGE_COMPANY_NAME: "Please enter your company name",
  ERROR_MESSAGE_JOB: "Please enter your job title",
  ERROR_MESSAGE_COMPANY_SIZE: "Please select your company size",
  ERROR_MESSAGE_PASSWORD: "Please enter your password"
};

export const KEYCODES = {
  BACKSPACE: 8,
  TAB: 9,
  ENTER: 13,
  SHIFT: 16,
  CTRL: 17,
  ALT: 18,
  PAUSE_BREAK: 19,
  CAPS_LOCK: 20,
  ESC: 27,
  SPACE: 32,
  PAGE_UP: 33,
  PAGE_DOWN: 34,
  END: 35,
  HOME: 36,
  LEFT_ARROW: 37,
  UP_ARROW: 38,
  RIGHT_ARROW: 39,
  DOWN_ARROW: 40,
  INSERT: 45,
  DELETE: 46,
  NUM_LOCK: 144,
  SCROLL_LOCK: 145
};

export const BREAKPOINTS = {
  PHONE: 480,
  TABLET: 640,
  TABLET_XL: 768,
  DESKTOP: 960,
  DESKTOP_LG: 1280,
  DESKTOP_MD: 1440,
  DESKTOP_XL: 1480,
};
